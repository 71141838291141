import {Navigate, Route, Routes} from 'react-router-dom';
import {NotificationView} from '../notification';
import {UserDetails} from './ui/UserDetails';
import {FavoriteProducts} from '../product';
import {OrderHistory} from '../order/ui/OrderHistory';
import {AddressOverviewContainer} from '../address/ui/AddressOverviewContainer';
import {Account} from './ui/Account';
import {Invoices} from './ui/Invoices';

export const AccountRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Account />}>
        <Route index element={<Navigate to="usuario" />} />
        <Route exact path="usuario" element={<UserDetails />} />
        <Route exact path="direcciones" element={<AddressOverviewContainer />} />
        <Route exact path="Pedidos" element={<OrderHistory />} />
        <Route exact path="Facturas" element={<Invoices />} />
        <Route exact path="Favoritos" element={<FavoriteProducts />} />
        <Route exact path="Alertas" element={<NotificationView />} />
      </Route>
    </Routes>
  );
};