/**
 * @typedef {Object} DateRange
 * @property {Date} from
 * @property {Date} to
 */

import {toLocalDateString} from '../utils/date.utils';

/**
 * @returns {DateRange}
 */
export const lastMonthDateRange = () => {
  const now = new Date();
  const oneMonthAgo = new Date(now);
  oneMonthAgo.setMonth(now.getMonth() - 1);
  return {
    from: oneMonthAgo,
    to: now,
  };
};

/**
 * @param {DateRange} dateRange
 * @param {string} separator
 * @returns {string}
 */
export const formatToLocalDateString = (dateRange, separator = '_') => {
  return `${toLocalDateString(dateRange.from)}${separator}${toLocalDateString(dateRange.to)}`;
}
