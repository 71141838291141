import {useInvoice} from '../application/useInvoice';
import {RemoteDataUI} from '../../common/ui/RemoteDataUI';
import {Spinner} from 'react-bootstrap';
import {Carritos, TableFactura} from './cuenta.elements';
import {spanishDateFormatter} from '../../ui/components/SpanishDateFormatter';
import {toast} from 'react-toastify';
import {downloadBlob} from '../../helpers/downloadBlob';
import {fetchPdfInvoice} from '../infrastructure/InvoiceApi';
import React, {useEffect} from 'react';
import {Indicador} from '../../ui';
import {DownloadingTwoTone, SwapVertTwoTone} from '@mui/icons-material';
import {DateRangePicker} from '../../common/ui/DateRangePicker';
import {isFailure, isPending} from '../../common/domain/RemoteData.model';

export const Invoices = () => {
  const {
    invoicesRemoteData,
    toggleSortOrder,
    dateRange,
    setDateRange,
    downloadInvoices,
    bulkInvoiceDownloadRemoteData
  } = useInvoice();

  const download = async (id, order) => {
    try {
      toast.info(`Descargando factura ${order}.`);
      downloadBlob(await fetchPdfInvoice(id), `factura-${order}.pdf`);
    } catch (error) {
      console.error(JSON.stringify(error));
      toast.error(`Error en la descarga de la factura ${order}.`);
    }
  };

  useEffect(() => {
    if (isPending(bulkInvoiceDownloadRemoteData)) {
      toast.info(`Descargando facturas.`);
    }
    if (isFailure(bulkInvoiceDownloadRemoteData)) {
      console.error(JSON.stringify(bulkInvoiceDownloadRemoteData.error));
      toast.error(`Error en la descarga de las facturas`);
    }
  }, [bulkInvoiceDownloadRemoteData]);

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <Indicador indicador={'Historial de facturas'} />
        <div>
          <DateRangePicker defaultDates={dateRange} onDateRangeChange={setDateRange} />
        </div>
      </div>
      <RemoteDataUI remoteData={invoicesRemoteData}>
        <RemoteDataUI.Pending>
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        </RemoteDataUI.Pending>

        <RemoteDataUI.Success>
          {
            invoices => invoices.length > 0 ? (
              <Carritos className="row gap-3">
                <div className="col-12">
                  <button className="btn btn-primary" type="button"
                          disabled={isPending(bulkInvoiceDownloadRemoteData)}
                          onClick={() => downloadInvoices(invoices)}>
                    <div className="d-flex flex-row align-items-center gap-2">
                      <span>Descargar facturas</span>
                      {isPending(bulkInvoiceDownloadRemoteData) && <Spinner size="sm" />}
                    </div>
                  </button>
                </div>
                <div className="col-xl-12">
                  <TableFactura>
                    <thead>
                    <tr>
                      <th>Nº de factura</th>
                      <th className="d-flex flex-row align-items-center gap-2">
                        <span>Vencimiento</span>
                        <SwapVertTwoTone
                          className="opacity-25"
                          style={{ cursor: 'pointer' }}
                          onClick={() => toggleSortOrder()}
                        />
                      </th>
                      <th>Importe</th>
                      <th>Descargar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      invoices.map((invoice, index) => (
                        <tr className="align-middle" key={index}>
                          <td>
                            <h5 className="m-0">{invoice.orderNumber}</h5>
                          </td>
                          <td>
                        <span className="text-primary">
                          {spanishDateFormatter(invoice.dueDate)}
                        </span>
                          </td>
                          <td className="d-flex justify-content-end">
                            {invoice.taxedAmount}€
                          </td>
                          <td>
                            <DownloadingTwoTone
                              className="text-primary me-3"
                              style={{ cursor: 'pointer' }}
                              onClick={() => download(invoice.id, invoice.orderNumber)}
                            />
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </TableFactura>
                </div>
              </Carritos>
            ) : (
                          <div className="alert alert-light">
                            No se encontraron facturas para las fechas seleccionadas. Por favor, pruebe con otro rango de fechas.
                          </div>
                        )
          }
        </RemoteDataUI.Success>

        <RemoteDataUI.Failure>
          <div className="alert alert-danger">
            <p>No es posible cargar las facturas. Por favor, reintente más tarde o contacte con nosotros.</p>
          </div>
        </RemoteDataUI.Failure>
      </RemoteDataUI>
    </div>
  );
};