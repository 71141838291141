/**
 * @typedef {Object} Invoice
 * @property {String} id
 * @property {String} orderNumber
 * @property {String} dueDate
 * @property {String} taxedAmount
 */

/**
 * @param {Invoice} invoices
 * @param {'asc' | 'desc'} order
 * @returns {Invoice[]}
 */
export const sortByDueDate = (invoices, order) => {
  return [...invoices].sort((a, b) => {
    const dateA = new Date(a.dueDate);
    const dateB = new Date(b.dueDate);

    if (order === 'asc') return dateA - dateB;
    return dateB - dateA;
  });
}
