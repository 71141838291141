import {fetchBulkPdfInvoices, fetchInvoicesInDueDateRange} from '../infrastructure/InvoiceApi';
import {useEffect, useState} from 'react';
import {initial, isSuccess, mapRemoteData} from '../../common/domain/RemoteData.model';
import {remoteDataWithSetter} from '../../common/utils/remoteDataWithSetter';
import {sortByDueDate} from '../domain/Invoice';
import {lastMonthDateRange} from '../../common/domain/DateRange.model';
import {downloadBlob} from '../../helpers/downloadBlob';

export const useInvoice = () => {
  const [invoicesRemoteData, setInvoiceRemoteData] = useState(initial(null));
  const [sortOrder, setSortOrder ] = useState('asc');
  const [dateRange, setDateRange] = useState(lastMonthDateRange());
  const [bulkInvoiceDownloadRemoteData, setBulkInvoiceDownloadRemoteData] = useState(initial(null));

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
  }

  const downloadInvoices = async (invoices) => {
    try {
      const invoiceIds = invoices.map(invoice => invoice.id);
      remoteDataWithSetter(setBulkInvoiceDownloadRemoteData, () => fetchBulkPdfInvoices(invoiceIds)).then();
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  useEffect(() => {
    remoteDataWithSetter(setInvoiceRemoteData, () => fetchInvoicesInDueDateRange(dateRange)).then();
  }, [dateRange]);

  useEffect(() => {
    setInvoiceRemoteData(prevState =>
      mapRemoteData(prevState, invoices => invoices && sortByDueDate(invoices, sortOrder)),
    );
  }, [sortOrder]);

  useEffect(() => {
    if (isSuccess(bulkInvoiceDownloadRemoteData)) {
      downloadBlob(bulkInvoiceDownloadRemoteData.value, "facturas.zip");
      setBulkInvoiceDownloadRemoteData(initial(null));
    }
  }, [bulkInvoiceDownloadRemoteData]);

  return { invoicesRemoteData, toggleSortOrder, dateRange, setDateRange, downloadInvoices, bulkInvoiceDownloadRemoteData };
}