import {useState} from 'react';
import {toLocalDateString} from '../utils/date.utils';
import {useDebouncedEffect} from '../utils/useDemouncedEffect';

/**
 * @type {DateRange}
 */
const unselectedDates = { from: null, to: null };

export const DateRangePicker = ({ onDateRangeChange, defaultDates = unselectedDates }) => {
  const [from, setFrom] = useState(defaultDates.from);
  const [to, setTo] = useState(defaultDates.to);
  const [touched, setTouched] = useState(false); // Prevents triggering change with default values

  const formatDateForInput = (date) => {
    return !date ? "" : toLocalDateString(date);
  };

  const parseDateFromInput = (value) => {
    return value ? new Date(value) : null;
  };

  const handleFromChange = (e) => {
    const value = parseDateFromInput(e.target.value);
    setFrom(value);
    if (to && value > to) {
      setTo(null); // Reset end date if start date is later
    }
    setTouched(true);
  };

  const handleToChange = (e) => {
    const value = parseDateFromInput(e.target.value);
    setTo(value);
    setTouched(true);
  };

  useDebouncedEffect(() => {
    if (from && to && touched) {
      onDateRangeChange({ from, to });
    }
  }, [from, to], 1000);

  return (
    <div className="d-flex gap-2 align-items-center">
      <div className="form-group">
        <input
          type="date"
          id="from-date"
          className="form-control"
          value={formatDateForInput(from)}
          onChange={handleFromChange}
        />
      </div>
      <span>-</span>
      <div className="form-group">
        <input
          type="date"
          id="to-date"
          className="form-control"
          value={formatDateForInput(to)}
          onChange={handleToChange}
          min={formatDateForInput(from) || ""} // Ensure end date can't be before start date
        />
      </div>
    </div>
  );
};
