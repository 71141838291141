import React from 'react';
import {FooterContent, FooterInfo, FooterLegalText, ImageFooter, ImageFooter2, LiFooter, TextTelefono, UlFooter, UlFooterInfo} from './footer.elements';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Link} from 'react-router-dom';
import {useTenantContext} from '../../../tenant/TenantContext';

function Footer(props) {
  const {
    primaryColor,
    businessName,
    tel,
    address,
    about,
    privacyPolicy,
    cookiesPolicy,
    logoFundacion,
    logoRecuperacion,
    legalText
  } = useTenantContext();

  const currentYear = new Date().getFullYear();
  return (
    <FooterInfo>
      <FooterContent>
        <div>
          <UlFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer"
                 href={about}>Sobre {businessName}</a>
            </LiFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer" href={privacyPolicy}>Politicas
                de privacidad
              </a>
            </LiFooter>
            <LiFooter>
              <a target="_blank" rel="noreferrer" href={cookiesPolicy}> Politicas
                de cookies
              </a>
            </LiFooter>
            {props.publicFooter ?
             <></> : <><LiFooter><Link to="/articulos">Catálogo de productos</Link></LiFooter>
                <LiFooter><Link to={'/laboratorios'}>Fabricantes</Link></LiFooter>
             </>
            }
          </UlFooter>
        </div>
        <div>
          <UlFooterInfo>
            <LiFooter>
              <PhoneIcon style={{ color: primaryColor }} /><span>Teléfono: </span>
              <TextTelefono>{tel}</TextTelefono>
            </LiFooter>
            <LiFooter>
              <LocationOnIcon style={{ color: primaryColor }} />
              <span>{address.split('-')[0]}<br />
                {address.split('-')[1]}</span>
            </LiFooter>
          </UlFooterInfo>
        </div>

        {
          !!legalText && (
            <FooterLegalText>
              {legalText}
              <div>
                {!!logoRecuperacion && <ImageFooter src={logoRecuperacion} alt="planrecuperacionytransformacion" />}
                {!!logoFundacion && <ImageFooter2 src={logoFundacion} alt="planrecuperacionytransformacion" />}
              </div>
            </FooterLegalText>
          )
        }
      </FooterContent>
      <div className="justify-content-center col-md-12 mb-4">
        <hr />
        {currentYear} © {businessName}
      </div>

    </FooterInfo>
  );
}

export {Footer};