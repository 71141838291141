/**
 * @typedef {Object} DealSelection
 * @property {DealType} type
 * @property {Offer | Lot | Promo} value
 */

/**
 * @typedef {Object} DealType
 * @property {'LOT'} LOT
 * @property {'OFFER'} OFFER
 * @property {'PROMO'} PROMO
 * @property {'NONE'} NONE
 */

export const DealType = {
  LOT: 'LOT',
  OFFER: 'OFFER',
  PROMO: 'PROMO',
  NONE: 'NONE',
};
