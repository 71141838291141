import React from 'react';
import {useTenantContext} from '../../../tenant/TenantContext';
import {useNavigate} from 'react-router-dom';

export const Navbar = ({ children }) => {
  const { logo } = useTenantContext();
  const navigate = useNavigate();

  const allChildren = React.Children.toArray(children);
  const controlsChild = allChildren.find(({ type }) => type === Navbar.Controls);
  const searchChild = allChildren.find(({ type }) => type === Navbar.Search);

  return (
    <nav className="container-fluid py-3 bg-body-tertiary">
      <div className="row gap-1 gap-lg-0 align-items-start align-items-xl-center justify-content-between">
        <div className="col-5 col-lg-auto">
          <img src={logo} style={{maxWidth: '250px', maxHeight: '75px'}} alt="Logo" onClick={() => navigate('/')} />
        </div>
        <div className="col-auto order-lg-last">
          {controlsChild}
        </div>
        <div className="col-12 col-lg">
          <div className="d-flex justify-content-center">
            {searchChild}
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.Controls = ({ children }) => <>{children}</>;
Navbar.Search = ({ children }) => <>{children}</>;
