export const loadImgAsset = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      resolve(url);
    };

    img.onerror = () => {
      console.warn(`Image not found: ${url}`);
      resolve(null);
    };
  });
};