import ApiClient, {requestRemoteData} from '../../API/api';
import {toLocalDateString} from '../../common/utils/date.utils';

const GET_INVOICES = 'invoices';
const GET_FILTERED_INVOICES = 'invoices/filter';
const GET_PDF_INVOICE = 'invoices/:invoiceId/download'
const GET_BULK_PDF_INVOICES = 'invoices/bulk/download'

/**
 * @returns {Promise<RemoteData<Invoice>>}
 */
export const fetchInvoices = () => {
  return requestRemoteData(GET_INVOICES);
};

/**
 *
 * @param {DateRange} dateRange - format YYYY-MM-DD
 * @returns {Promise<RemoteData<Invoice[]>>}
 */
export const fetchInvoicesInDueDateRange = (dateRange) => {
  return requestRemoteData(
    GET_FILTERED_INVOICES,
    {
      queryParams: {
        fromDueDate: toLocalDateString(dateRange.from),
        toDueDate: toLocalDateString(dateRange.to)
      }
    });
};

/**
 * @param {string} invoiceId
 * @return Promise<Blob>
 */
export const fetchPdfInvoice = (invoiceId) => {
  return ApiClient.performRequest(
    GET_PDF_INVOICE,
    {
      pathParams: { invoiceId },
      headers: { 'accept': 'application/pdf', 'Content-Type': 'application/pdf' }
    });
}

/**
 * @param {string[]} invoiceIds
 * @return Promise<RemoteData<Blob>>
 */
export const fetchBulkPdfInvoices = (invoiceIds) => {
  return requestRemoteData(
    GET_BULK_PDF_INVOICES,
    {
      method: 'POST',
      headers: { 'accept': 'application/octet-stream' },
      body: invoiceIds
    });
}
